.centered {
  margin: auto;
}

.centerContent {
  text-align: center;
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
}

#section1 h1 {
  font-family: "Raleway", sans-serif;
  font-size: 48px;
  line-height: 1;
  font-weight: 1500;
  color:#6993b8;
  padding: 0;
  margin-bottom: 10px;
  margin-left: 0;
}

#section1 h2 {
  font-family: "Raleway", sans-serif;
  line-height: 1;
  padding: 0;
  font-size: 25px;
  margin: 0;
  font-weight: 550;
}

#section1 p {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
}

#section1 img {
  position: absolute;
  width: 25%;
}

#section1 .btnContactUs {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #6993b8;
  border: 3px solid #6993b8;
}

#section2 h2 {
  font-size: 30px;
  margin: 0;
  font-weight: bold;
}

#section2 h2:before, #section2 h2:after {
  content: "";
  display: inline-block;
  width: 30%;
  margin: 0 .5em 0 -25%;
  vertical-align: middle;
  border-bottom: 7px solid;
}

#section2 h2:after {
  margin: 0 -25% 0 .5em;
}

.paperBox {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.paperBox::before {
  content: '';
  position: absolute;
  background: #e1f0fa;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}

.paperBox:hover::before {
  background: #3498db;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.icon {
  margin: 0 auto 20px auto;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #3498db;
  transition: all 0.3s ease-in-out;
}

.icon i {
  font-size: 55px;
  line-height: 1;
  color: #fff;
}

.paperBox:hover {
  color: #fff;
}

.paperBox:hover .icon {
  background: lightblue;
}

.paperBox:hover .icon i {
  color: #3498db;
}

.paperBox:hover p {
  color: #ffffff;
}

.paperBox p {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

#section2 p {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

#section2 img {
  width: 100%;
}

.listContent {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}
