.formatParagraph {
    font-family: 'Raleway', sans-serif;
    margin: 0;
    padding: 0;
}

.formatCompanyName {
    font-size: 16px;
    font-weight: bold;
}

.icon {
    color: white;
    background: #000000;
}